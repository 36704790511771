import i18n from '@/i18n';
import { state } from '@/state/modules/authfack.js';

const campaignDefault = state?.user?.default_campaign ?? null;

const TABS = [
    {
        id: 1,
        name: '基本設定',
        disabled: false
    },
    {
        id: 2,
        name: 'ファーストビュー＆応募期間',
        disabled: false
    },
    {
        id: 3,
        name: '賞品設定',
        disabled: false
    },
    {
        id: 4,
        name: '対象商品',
        disabled: false
    },
    {
        id: 5,
        name: '応募方法設定',
        disabled: false
    },
    {
        id: 6,
        name: 'レシート撮影の注意事項 応募規約',
        disabled: false
    },
    {
        id: 7,
        name: 'デザイン設定',
        disabled: false
    }
];

const STATUS_SETTING_BASIC = [
    {
        id: 1,
        value: '公開'
    },
    {
        id: 2,
        value: '下書き'
    },
    {
        id: 3,
        value: '終了'
    }
];

const STATUS_SETTING_PRIZE = [
    {
        id: '0',
        value: '有効'
    },
    {
        id: 1,
        value: '無効の切り替え'
    }
];

const STATUS_FORM = [
    {
        id: 1,
        value: '公開前'
    },
    {
        id: 2,
        value: '終了後'
    },
    {
        id: 3,
        value: '下書き'
    }
];

const configs = {
    campaign: {
        trackBy: 'id',
        label: 'name',
        error: false,
        isRemove: false
    },
    nameLandingPage: {
        trackBy: 'id',
        label: 'name',
        error: false,
        isRemove: false
    },
    client: {
        trackBy: 'id',
        label: 'name',
        error: false,
        isRemove: false
    },
    configTable: {
        headers: [
            {
                label: i18n.t('landingPage.list.lpID')
            },
            {
                label: i18n.t('landingPage.list.nameCampaign')
            },
            {
                label: i18n.t('landingPage.list.nameLandingPage')
            },
            {
                label: i18n.t('landingPage.list.urlPublic')
            },
            {
                label: i18n.t('landingPage.list.status')
            },
            {
                label: i18n.t('landingPage.list.startDateLP')
            },
            {
                label: i18n.t('landingPage.list.endDateLP')
            }
        ]
    },
    configPage: {
        total: 0,
        limit: 10,
        page: 1
    }
};

const LIST_FONT_PAGE = [
    {
        id: 1,
        name: 'Yuji Mai, serif',
        url: 'https://fonts.googleapis.com/css2?family=Yuji+Mai&display=swap',
        property_style: {
            fontFamily: "'Yuji Mai', serif",
            fontWeight: 400,
            fontStyle: 'normal'
        }
    },
    {
        id: 2,
        name: 'Noto Sans JP, sans-serif',
        url: 'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Yuji+Mai&display=swap',
        property_style: {
            fontFamily: "'Yuji Mai', serif",
            fontWeight: 400,
            fontStyle: 'normal'
        }
    },
    {
        id: 3,
        name: 'Hachi Maru Pop, cursive',
        url: 'https://fonts.googleapis.com/css2?family=Hachi+Maru+Pop&family=Noto+Sans+JP:wght@100..900&family=Yuji+Mai&display=swap',
        property_style: {
            fontFamily: "'Hachi Maru Pop', cursive",
            fontWeight: 400,
            fontStyle: 'normal'
        }
    }
];

const SQUARE_TYPE_PAGE = [
    {
        id: 1,
        value: 'スクエア'
    },
    {
        id: 2,
        value: 'サークル'
    }
];

const initFormSearch = () => {
    return {
        campaign_name: null,
        name: null,
        sale_staff: '',
        campaign_start_date: '',
        campaign_end_date: '',
        lp_start_date: '',
        lp_end_date: ''
    };
};

const initFormRegister = () => {
    return {
        //----- setting basic --------------
        campaign: campaignDefault,
        code: '',
        name: '',
        number_cp: '',
        sale_staff: '',
        person_in_charge: '',
        zac_id: '',
        sfid: '',
        campaign_entity_id: '',
        //  start date cp
        campaign_start_date: '',
        campaign_start_time: '',
        //  end date cp
        campaign_end_date: '',
        campaign_end_time: '',
        //  start date lp
        lp_start_date: '',
        lp_start_time: '',
        //  end date lp
        lp_end_date: '',
        lp_end_time: '',
        status: '',
        keywords: [],
        description: '',
        analytics: '',
        og_image: null,
        url_public: '',
        application_form_link: '',
        x_url: '',
        x_share_text: '',
        x_hashtag: [],
        //----- end setting basic --------------

        //----- start setting firstview --------------
        first_view: {
            copy: '',
            sub_copy: '',
            sub_copy_2: '',
            title_image: ''
        },
        application_period: {
            campaign_start_date: '',
            campaign_start_time: '',
            campaign_end_date: '',
            campaign_end_time: '',
            application_deadline: '',
            overview: ''
        }
        //----- end setting firstview --------------
    };
};

export { TABS, STATUS_FORM, configs, LIST_FONT_PAGE, SQUARE_TYPE_PAGE, STATUS_SETTING_BASIC, STATUS_SETTING_PRIZE, initFormSearch, initFormRegister };
