const OPTIONS_TYPE = [
    {
        id: 1,
        name: '代理店'
    },
    {
        id: 2,
        name: 'メーカー'
    },
    {
        id: 3,
        name: '流通'
    }
];

export { OPTIONS_TYPE };
