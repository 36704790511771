<template>
    <div>
        <ModalCommon
            id="ModalOrder"
            :config="configModalCommon"
            @save="handleSaveScopeOrder()"
            @close="handleCloseScopeOrder()"
            :isView="$props.isView"
        >
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-2 mw--form" for="validationCustom05">受注範囲</label>
                    <div class="col-sm-9">
                        <div class="d-flex flex-wrap">
                            <div style="min-width: 100px" v-for="orderLv1 in listMasterOrderScope" :key="orderLv1.id">
                                <InputCheckBox
                                    class="mr-4 py-1"
                                    :model.sync="listChecked.msOrderLv1"
                                    :id="`check_app${orderLv1.id}`"
                                    :value="orderLv1.id"
                                    :label="orderLv1.value"
                                    :disabled="$props.isView"
                                    @change="handleChangeOrderLv1(orderLv1)"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="listChecked.msOrderLv1.length">
                    <div
                        v-for="itemFilterLv1 in listOrderFilterLv1"
                        :key="`${itemFilterLv1.id}-filter1`"
                        class="py-3 mt-3"
                        style="box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px; border-radius: 10px"
                    >
                        <div>
                            <div class="ml-1 my-4">
                                <label class="custom-control px-3">{{ itemFilterLv1.value }}</label>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-1"></div>
                                <div class="col-sm-11 row">
                                    <div v-for="(dataFilterLv1, idxF1) in itemFilterLv1.data" :key="`${idxF1}-medium_item_filter1`">
                                        <InputCheckBox
                                            class="mr-4 py-1"
                                            style="min-width: 100px"
                                            :model.sync="listChecked.msOrderLv2"
                                            :id="`check_app${dataFilterLv1.id}`"
                                            :value="dataFilterLv1.id"
                                            :label="dataFilterLv1.value"
                                            :disabled="$props.isView"
                                            @change="handleChangeOrderLv2(dataFilterLv1)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-for="itemFilterLv2 in listOrderFilterLv2" :key="`${itemFilterLv2.id}-filter2`">
                            <div v-if="handleVisibleLv2(itemFilterLv1, itemFilterLv2)">
                                <div v-if="itemFilterLv2.data.length > 0" style="border-top: 1px solid rgb(181 180 180 / 50%)">
                                    <div class="ml-4 my-4">
                                        <label class="custom-control px-2"
                                            >{{ itemFilterLv1.value }} <span class="font-weight-bold px-1">></span> {{ itemFilterLv2.value }}</label
                                        >
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-sm-1"></div>
                                        <div class="col-sm-11 row">
                                            <div v-for="(dataFilterLv2, idxF2) in itemFilterLv2.data" :key="`${idxF2}-medium_item_filter2`">
                                                <div v-if="handleShowOptionOneFilterLv2(itemFilterLv2)">
                                                    <InputRadio
                                                        class="mr-4"
                                                        :model.sync="listRadio.msOrderLv2[itemFilterLv2.id]"
                                                        :name="`msOrderLv2-${itemFilterLv2.id}`"
                                                        :id="`radio-${dataFilterLv2.id}`"
                                                        :value="dataFilterLv2.id"
                                                        :label="dataFilterLv2.value"
                                                        :disabled="$props.isView"
                                                    />
                                                </div>
                                                <div v-else>
                                                    <InputCheckBox
                                                        class="mr-4 py-1"
                                                        style="min-width: 100px"
                                                        :model.sync="listChecked.msOrderLv3"
                                                        :id="`check_app${dataFilterLv2.id}`"
                                                        :value="dataFilterLv2.id"
                                                        :label="dataFilterLv2.value"
                                                        :disabled="$props.isView"
                                                        @change="handleChangeOrderLv3(dataFilterLv2)"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-for="itemFilterLv3 in listOrderFilterLv3" :key="`${itemFilterLv3.id}-filter3`">
                                <div v-if="handleVisibleLv3(itemFilterLv1, itemFilterLv2, itemFilterLv3)">
                                    <div v-if="itemFilterLv3.data.length > 0" style="border-top: 1px solid rgb(181 180 180 / 50%)">
                                        <div class="ml-4 my-4">
                                            <label class="custom-control"
                                                >{{ itemFilterLv1.value }} <span class="font-weight-bold px-1">></span> {{ itemFilterLv2.value }}
                                                <span class="font-weight-bold px-1">></span> {{ itemFilterLv3.value }}</label
                                            >
                                        </div>

                                        <div class="form-group row">
                                            <div class="col-sm-1"></div>
                                            <div class="col-sm-11 row">
                                                <div v-for="(dataFilterLv3, idxF3) in itemFilterLv3.data" :key="`${idxF3}-medium_item_filter3`">
                                                    <div v-if="handleShowOptionOneFilterLv3(itemFilterLv3)">
                                                        <InputRadio
                                                            class="mr-4"
                                                            :model.sync="listRadio.msOrderLv4[itemFilterLv3.id]"
                                                            :name="`radio-filter-lv4-${itemFilterLv3.id}`"
                                                            :id="`radio-filter-${dataFilterLv3.id}`"
                                                            :value="dataFilterLv3.id"
                                                            :label="dataFilterLv3.value"
                                                            :disabled="$props.isView"
                                                        />
                                                    </div>
                                                    <div v-else>
                                                        <InputCheckBox
                                                            class="mr-4 py-1"
                                                            style="min-width: 100px"
                                                            :model.sync="listChecked.msOrderLv4"
                                                            :id="`check_app${dataFilterLv3.id}`"
                                                            :value="dataFilterLv3.id"
                                                            :label="dataFilterLv3.value"
                                                            :disabled="$props.isView"
                                                            @change="handleChangeOrderLv4(dataFilterLv3)"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ModalCommon>
    </div>
</template>

<script>
import { InputCheckBox, InputRadio } from '@/components/Input';
import {
    funcOrderLv1,
    funcOrderLv2,
    funcOrderLv3,
    funcOrderLv4,
    funcOptionOneFilterLv2,
    funcOptionOneFilterLv3,
    funcMapDataRegisted
} from '../../index';
import ModalCommon from '@/components/Modal/main.vue';

export default {
    name: 'ModalScopeOrder',
    components: {
        ModalCommon,
        InputCheckBox,
        InputRadio
    },
    props: {
        dataMasterOrderScope: {
            type: Array,
            default: () => []
        },
        dataScope: {
            type: Array,
            default: () => []
        },
        dataForm: {
            type: Object,
            default: () => {}
        },
        dataContactInformations: {
            type: Object,
            default: () => {}
        },
        isShow: {
            type: Boolean,
            default: false
        },
        isView: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            form: this.dataForm,
            contact_informations: this.dataContactInformations,
            listMasterOrderScope: [],
            listOrderFilterLv1: [],
            listOrderFilterLv2: [],
            listOrderFilterLv3: [],
            listOrderFilterLv4: [],
            listChecked: {
                msOrderLv1: [],
                msOrderLv2: [],
                msOrderLv3: [],
                msOrderLv4: []
            },
            listRadio: {
                msOrderLv2: {},
                msOrderLv4: {}
            },
            configModalCommon: {
                title: '受注範囲',
                model: false,
                scrollable: true,
                hideFooter: false,
                class: 'modal-custom-tab-basic-cp'
            }
        };
    },
    methods: {
        handleChangeOrderLv1(itemLv1) {
            const resultLv1 = funcOrderLv1(
                itemLv1,
                this.listChecked,
                this.listOrderFilterLv1,
                this.listOrderFilterLv2,
                this.listOrderFilterLv3,
                this.listOrderFilterLv4,
                this.listRadio,
                this.contact_informations
            );
            this.listChecked = resultLv1.listChecked;
            this.listOrderFilterLv1 = resultLv1.listOrderFilterLv1;
            this.listOrderFilterLv2 = resultLv1.listOrderFilterLv2;
            this.listOrderFilterLv3 = resultLv1.listOrderFilterLv3;
            this.listOrderFilterLv4 = resultLv1.listOrderFilterLv4;
            this.listRadio = resultLv1.listRadio;
            this.contact_informations = resultLv1.contact_informations;
            this.$emit('changeOrderLv', {
                scopeChecked: this.listChecked,
                scopeRadio: this.listRadio
            });
        },

        handleChangeOrderLv2(itemLv2) {
            const resultLv2 = funcOrderLv2(
                itemLv2,
                this.listChecked,
                this.listOrderFilterLv2,
                this.listOrderFilterLv3,
                this.listOrderFilterLv4,
                this.listRadio,
                this.contact_informations
            );
            this.listChecked = resultLv2.listChecked;
            this.listOrderFilterLv2 = resultLv2.listOrderFilterLv2;
            this.listOrderFilterLv3 = resultLv2.listOrderFilterLv3;
            this.listOrderFilterLv4 = resultLv2.listOrderFilterLv4;
            this.listRadio = resultLv2.listRadio;
            this.contact_informations = resultLv2.contact_informations;
            this.$emit('changeOrderLv', {
                scopeChecked: this.listChecked,
                scopeRadio: this.listRadio
            });
        },

        handleChangeOrderLv3(itemLv3) {
            const resultLv3 = funcOrderLv3(
                itemLv3,
                this.listChecked,
                this.listOrderFilterLv3,
                this.listOrderFilterLv4,
                this.listRadio,
                this.contact_informations
            );
            this.listChecked = resultLv3.listChecked;
            this.listOrderFilterLv3 = resultLv3.listOrderFilterLv3;
            this.listOrderFilterLv4 = resultLv3.listOrderFilterLv4;
            this.listRadio = resultLv3.listRadio;
            this.contact_informations = resultLv3.contact_informations;
            this.$emit('changeOrderLv', {
                scopeChecked: this.listChecked,
                scopeRadio: this.listRadio
            });
        },

        handleChangeOrderLv4(itemLv4) {
            const resultLv4 = funcOrderLv4(itemLv4, this.listChecked, this.listOrderFilterLv4, this.contact_informations);
            this.listChecked = resultLv4.listChecked;
            this.listOrderFilterLv4 = resultLv4.listOrderFilterLv4;
            this.contact_informations = resultLv4.contact_informations;
        },

        handleVisibleLv2(itemLv1, itemLv2) {
            const result = itemLv1.data.map((item) => item.id).includes(itemLv2.id);
            return result;
        },

        handleVisibleLv3(itemLv1, itemLv2, itemLv3) {
            const resultItemLv2 = itemLv2.data.find((item) => item.id === itemLv3.id);
            if (resultItemLv2) {
                return resultItemLv2.origin_parent_id === itemLv1.id;
            }
        },

        handleSaveScopeOrder() {
            this.$emit('saveScopeOrderModal', {
                scopeChecked: this.listChecked,
                scopeRadio: this.listRadio
            });
            this.$emit('update:isShow', false);
        },

        handleCloseScopeOrder() {
            this.listChecked = {
                msOrderLv1: [],
                msOrderLv2: [],
                msOrderLv3: [],
                msOrderLv4: []
            };
            this.listRadio = {
                msOrderLv2: {},
                msOrderLv4: {}
            };
            this.listOrderFilterLv1 = [];
            this.listOrderFilterLv2 = [];
            this.listOrderFilterLv3 = [];
            this.listOrderFilterLv4 = [];
            this.handleMapDataRegisted();
            this.$emit('update:isShow', false);
        },

        handleMapDataRegisted() {
            funcMapDataRegisted(
                this.form,
                this.listChecked,
                this.listRadio,
                this.listOrderFilterLv1,
                this.listOrderFilterLv2,
                this.listOrderFilterLv3,
                this.listOrderFilterLv4
            );
            this.$emit('saveScopeOrderModal', {
                scopeChecked: this.listChecked,
                scopeRadio: this.listRadio
            });
        },

        handleWatchOpen() {
            this.listOrderFilterLv1 = [];
            this.listOrderFilterLv2 = [];
            this.listOrderFilterLv3 = [];
            this.listOrderFilterLv4 = [];
            // check data be trả về
            this.listChecked.msOrderLv1.forEach((idOrigin) => {
                const obj = this.listMasterOrderScope.find((x) => x.id === idOrigin);
                if (obj) {
                    this.handleChangeOrderLv1(obj);
                    obj.data.forEach((lv1) => {
                        this.handleChangeOrderLv2(lv1);
                        lv1.data.forEach((lv2) => {
                            this.handleChangeOrderLv3(lv2);
                        });
                    });
                }
            });
        },

        handleShowOptionOneFilterLv2(itemFilterLv2) {
            return funcOptionOneFilterLv2(itemFilterLv2);
        },

        handleShowOptionOneFilterLv3(itemFilterLv3) {
            return funcOptionOneFilterLv3(itemFilterLv3);
        }
    },
    watch: {
        isShow: {
            handler: function (newIsShow) {
                this.configModalCommon.model = newIsShow;
                if (newIsShow) {
                    this.handleWatchOpen();
                }
            },
            immediate: true,
            deep: true
        },
        dataScope: {
            handler: function () {
                this.handleMapDataRegisted();
            },
            immediate: true
        },
        dataMasterOrderScope: {
            handler: function (newDataMaster) {
                this.listMasterOrderScope = newDataMaster;
            },
            immediate: true
        }
    }
};
</script>
