import { formatDateCommon, formatMergeDateAndTime, STR_DATE_TIME } from '@/utils/format';
import { showMessage } from '@/utils/messages';
import { TABS } from './constants';
import moment from 'moment';
import i18n from '@/i18n';

function handleDataBeforeSaveLP(dataForm) {
    return {
        ...(dataForm.id !== null &&
            dataForm.id !== undefined && {
                id: dataForm.id
            }),
        campaign: dataForm?.campaign?.id
            ? {
                  id: dataForm?.campaign?.id
              }
            : null,
        name: dataForm.name,
        url_public: dataForm.url_public,
        lp_start_date: formatMergeDateAndTime(dataForm.lp_start_date, dataForm.lp_start_time),
        lp_end_date: formatMergeDateAndTime(dataForm.lp_end_date, dataForm.lp_end_time),
        campaign_start_date: formatMergeDateAndTime(dataForm.campaign_start_date, dataForm.campaign_start_time),
        campaign_end_date: formatMergeDateAndTime(dataForm.campaign_end_date, dataForm.campaign_end_time),
        status: dataForm.status,
        keywords: dataForm.keywords,
        description: dataForm.description,
        analytics: dataForm.analytics,
        application_form_link: dataForm.application_form_link,
        og_image: dataForm?.og_image?.id
            ? {
                  id: dataForm?.og_image?.id
              }
            : null,
        x_url: dataForm.x_url ?? '',
        x_share_text: dataForm.x_share_text,
        x_hashtag: dataForm.x_hashtag,

        //----- start setting firstview --------------
        first_view: {
            copy: dataForm.first_view.copy,
            sub_copy: dataForm.first_view.sub_copy,
            sub_copy_2: dataForm.first_view.sub_copy_2,
            title_image: dataForm.first_view.title_image?.id
                ? {
                      id: dataForm.first_view.title_image?.id
                  }
                : null
        },
        application_period: {
            application_deadline: dataForm.application_period.application_deadline,
            overview: dataForm.application_period.overview
        }
        //----- end setting firstview --------------
    };
}

function handleDataResponse(dataForm, data) {
    dataForm.id = data?.id ?? null;
    dataForm.sale_staff = data?.campaign?.sale_staff ?? '';
    dataForm.zac_id = data?.campaign?.zac_id;
    dataForm.sfid = data?.campaign?.sfid;
    dataForm.campaign_entity_id = data?.campaign?.campaign_entity_id;
    dataForm.person_in_charge = data?.campaign?.person_in_charge;
    dataForm.campaign = data?.campaign ?? null;
    dataForm.code = data?.code ?? null;
    dataForm.name = data?.name ?? '';
    dataForm.url_public = data?.url_public;
    dataForm.lp_start_time = formatDateCommon(data?.lp_start_date, 'dateTime');
    dataForm.lp_end_time = formatDateCommon(data?.lp_end_date, 'dateTime');
    dataForm.campaign_start_time = formatDateCommon(data?.campaign_start_date, 'dateTime');
    dataForm.campaign_end_time = formatDateCommon(data?.campaign_end_date, 'dateTime');
    dataForm.lp_start_date = formatDateCommon(data?.lp_start_date, 'date');
    dataForm.lp_end_date = formatDateCommon(data?.lp_end_date, 'date');
    dataForm.campaign_start_date = formatDateCommon(data?.campaign_start_date, 'date');
    dataForm.campaign_end_date = formatDateCommon(data?.campaign_end_date, 'date');
    dataForm.status = data?.status;
    dataForm.keywords = data?.keywords;
    dataForm.description = data?.description;
    dataForm.analytics = data?.analytics;
    dataForm.og_image = data?.og_image ?? null;
    dataForm.x_url = data?.x_url ?? '';
    dataForm.x_share_text = data?.x_share_text;
    dataForm.x_hashtag = data?.x_hashtag;

    //----- start setting firstview --------------
    dataForm.first_view = {
        copy: data?.first_view?.copy,
        sub_copy: data?.first_view?.sub_copy,
        sub_copy_2: data?.first_view?.sub_copy_2,
        title_image: data?.first_view?.title_image ?? null
    };
    dataForm.application_period = {
        campaign_start_time: formatDateCommon(data?.campaign_start_date, 'dateTime'),
        campaign_end_time: formatDateCommon(data?.campaign_end_date, 'dateTime'),
        campaign_start_date: formatDateCommon(data?.campaign_start_date, 'date'),
        campaign_end_date: formatDateCommon(data?.campaign_end_date, 'date'),
        application_deadline: data?.application_period?.application_deadline,
        overview: data?.application_period?.overview
    };
    //----- end setting firstview --------------
    return dataForm;
}

function handleMapDataFromStateLP(dataForm, allInforCampaignLP) {
    dataForm.sale_staff = allInforCampaignLP?.sale_staff ?? '';
    dataForm.zac_id = allInforCampaignLP?.zac_id;
    dataForm.sfid = allInforCampaignLP?.sfid;
    dataForm.campaign_entity_id = allInforCampaignLP?.campaign_entity_id;
    dataForm.campaign_start_time = formatDateCommon(allInforCampaignLP?.campaign_start_date, 'dateTime');
    dataForm.campaign_end_time = formatDateCommon(allInforCampaignLP?.campaign_end_date, 'dateTime');
    dataForm.campaign_start_date = formatDateCommon(allInforCampaignLP?.campaign_start_date, 'date');
    dataForm.campaign_end_date = formatDateCommon(allInforCampaignLP?.campaign_end_date, 'date');
    dataForm.application_period.campaign_start_time = formatDateCommon(allInforCampaignLP?.campaign_end_date, 'dateTime');
    dataForm.application_period.campaign_end_time = formatDateCommon(allInforCampaignLP?.campaign_end_date, 'dateTime');
    dataForm.application_period.campaign_start_date = formatDateCommon(allInforCampaignLP?.campaign_start_date, 'date');
    dataForm.application_period.campaign_end_date = formatDateCommon(allInforCampaignLP?.campaign_end_date, 'date');
    return dataForm;
}

function validateEndDate(startDateStr, endDateStr) {
    let check = true;
    const startDate = moment(startDateStr.valueSync, STR_DATE_TIME);
    const endDate = moment(endDateStr.valueSync, STR_DATE_TIME);
    if (!startDateStr.valueSync && !endDateStr.valueSync) return check;
    if (!endDate.isSameOrAfter(startDate)) {
        check = false;
        endDateStr.error = true;
        endDateStr.errorText = i18n.t('validateField.compareDate');
    }
    return check;
}

function handleAlertValidateTabs(conditionTab, bvToast, modelTab) {
    let check = true;
    const tabBasic = !conditionTab.checkBasicLP || !conditionTab.checkEndDateCP || !conditionTab.checkEndDateLP;
    const tabFirstView = !conditionTab.checkFirstView;

    if (tabBasic && modelTab !== 0) {
        showMessage(
            8080,
            bvToast,
            i18n.t('validateField.tabs', {
                field: TABS[0].name
            })
        );
    }
    if (tabFirstView && modelTab !== 1) {
        showMessage(
            8080,
            bvToast,
            i18n.t('validateField.tabs', {
                field: TABS[1].name
            })
        );
    }

    if (tabBasic || tabFirstView) {
        check = false;
    }

    return check;
}
export { handleDataBeforeSaveLP, handleMapDataFromStateLP, validateEndDate, handleDataResponse, handleAlertValidateTabs };
